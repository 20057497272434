.root {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 1100px;
    padding: 100px 20px;
    gap: 20px;
    margin: auto;
}

.root img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 800px) {
    .root {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 550px) {
    .root {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 380px) {
    .root {
        grid-template-columns: 1fr;
        padding: 20px;
    }
}