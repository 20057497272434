.hero-images {
  display: flex;
  justify-content: center;
}

.hero-images img {
  flex: 1;
  object-fit: cover;
  max-width: 33.33%;
}

@media (max-width: 725px) {
  .contact-wrapper {
    display: none;
  }
}


.expert-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  gap: 50px;
  padding: 50px 20px;
  box-sizing: border-box;
}

.expert-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  text-align: center;
}

.expert-heading-top {
  font-weight: 300;
  font-size: 2em;
  line-height: 1.2;
  margin-bottom: 0.5em;
}

.expert-heading-bottom {
  font-weight: 400;
  font-size: 1.5em;
  line-height: 1.2;
}

.expert-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
}

.expert-paragraph-top {
  margin-bottom: 20px;
}

@media (max-width: 900px) {
  .expert-wrapper {
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
  }

  .expert-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
  }

  .expert-heading-top {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 1.5em;
    line-height: 1.2;
  }

  .expert-heading-bottom {
    display: flex;
    justify-content: center;
    font-size: 1em;
    line-height: 1.2;
  }

  .button-home {
    margin-bottom: 10px;
  }
}

.button-home {
  margin-top: 20px;
  margin-right: 30px;
}

.services-wrapper {
  width: 100%;
  background-color: rgb(247, 241, 241);
}

.services-heading-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

.services-images {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 900px) {
  .services-images {
    flex-direction: column;
  }
}

.services-image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  transition: color 0.6s ease-in-out;
}

.services-image-wrapper:hover {
  color: #CF1717;
}

.services-main-heading {
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.services-paragraph {
  padding-left: 20px;
  padding-right: 20px;
}

.services-single-image {
  max-width: 100%;
  height: auto;
  margin: 30px;
  -webkit-box-shadow: -2px -1px 22px 5px rgba(0, 0, 0, 0);
  -moz-box-shadow: -2px -1px 22px 5px rgba(0, 0, 0, 0);
  box-shadow: -2px -1px 22px 5px rgba(0, 0, 0, 0);
  /* Set transition properties */
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -moz-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
}

.services-single-image:hover {
  -webkit-box-shadow: -2px -1px 22px 5px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: -2px -1px 22px 5px rgba(0, 0, 0, 0.32);
  box-shadow: -2px -1px 22px 5px rgba(0, 0, 0, 0.32);
}

.call-us-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.trained-professionals-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.trained-professionals-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.workers-single-image {
  max-height: 400px;
  max-width: 400px;
  padding: 30px;
}

h1 {
  margin: 0;
}

.testing-wrap {
  margin-left: 15px;
  margin-right: 15px;
}

.free-quotes-button-expert {
  display: inline-flex;
  align-items: center;
  position: relative;
  background-color: transparent;
  border: .5px solid #489FE7;
  padding: 10px 30px;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
  border-radius: 15px;
}

.free-quotes-button-expert::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #489FE7;
  z-index: -1;
  transition: height 0.3s ease-in-out;
}

.free-quotes-button-expert:hover {
  background-color: transparent;
  color: white;
}

.free-quotes-button-expert:hover::before {
  height: 100%;
}

.react-slideshow-container img{
  width: 100%;
  min-height: 300px;
  object-fit: cover;
}

.react-slideshow-container button.default-nav {
  margin: 20px;
}


@media (min-width: 600px) {
  .react-slideshow-container .images-wrap {
    transform: initial !important;
  }
  .react-slideshow-container button.default-nav {
    display: none;
  }
}
/* .hero-images {
  display: none;
}

@media (min-width: 767px) {
  .react-slideshow-container {
    display: none !important;
  }
  .hero-images {
    display: flex;
  }
} */
