.main-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 50px 0;
    position: relative; /* add this line */
    border-top: 1px solid #F68041;
  }
  .footer-container a:not(:hover) {
    color: inherit;
  }
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1100px;
    width: 100%;
    color: black;
    padding: 30px 20px;
    box-sizing: border-box;
  }
  
  .left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 35%;
  }
  
  .main-logo {
    width: 150px;
  }

  .main-footer .main-logo {
    margin-bottom: 20px;
  }
  
  .middle-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .right-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  p {
    line-height: 1.5;
    font-size: 16px;
  }
  
  .watermark {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid black;
    background-color: rgb(247, 241, 241);
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .footer-paragraph{
    padding-bottom: 20px;
  }

  .single-information-list{
    padding: 10px;
    font-weight: 200px;
  }

  .single-services-list{
    padding: 10px;
    font-weight: 200px;
  }

  @media screen and (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
    .left-container {
      width: 100%;
    }
    .middle-container,
    .right-container {
      width: 80%;
      margin-top: 30px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .middle-container,
    .right-container {
      width: 100%;
    }
  }

  