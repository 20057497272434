/* basic styling corrections */

ul {
  list-style: none;
}

li:before {
  content: none;
}
.navbar{
  position: relative;
  z-index: 10;
}

a {
  text-decoration: none;
  color: #489FE7;
}

p{
    margin: 0;
}
/* -------------------------- */

.top-nav {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #F02636;
    justify-content: space-around;
    align-items: center;
  }

.phone-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bottom-nav{
    display: flex;
    align-items: center;
    justify-content: center;
    
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
}
.bottom-nav-items{
    display: flex;
    flex-direction: row;
    padding-left: 0px;
}

.single-nav-item{
    display: flex;
    padding: 10px;
    font-size: 20px;
    font-weight: 100;
    border-bottom: 2px solid transparent;
}
.single-nav-item:hover{
    border-bottom: 1px solid #489FE7;
}

.contact-description{
    padding: 0;
    font-size: 20px;
    font-weight: 100;
}

.contact-wrapper{
    display: flex;
}

.phone-number{
    font-size: 20px;
    color: black;
}

.free-quotes-button {
    display: inline-flex;
    align-items: center;
    position: relative;
    background-color: transparent;
    margin-left: 60px;
    border: .5px solid #489FE7;
    padding: 10px 30px;
    overflow: hidden;
    transition: background-color 0.3s ease-in-out;
    border-radius: 15px;
  }
  
  .free-quotes-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #489FE7;
    z-index: -1;
    transition: height 0.3s ease-in-out;
  }
  
  .free-quotes-button:hover {
    background-color: transparent;
    color: white;
  }
  
  .free-quotes-button:hover::before {
    height: 100%;
  }

  .menu-close-button {
    display: none;
  }

  @media screen and (max-width: 500px) {
    .bottom-nav-items{
      flex-direction: column;
    }
    .bottom-nav {
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
      position: absolute;
    }
    .menu-close-button {
      display: block;
      background-color: transparent;
      outline: none;
      padding: 0.75rem;
      border: none;
      position: relative;
      box-sizing: border-box;
    }
    .menu-close-button::before, .menu-close-button::after {
      content: '';
      position: absolute;
      border: 1px solid;
      height: 100%;
      top: 0;
    }
    .menu-close-button::before {
      transform: rotate(45deg);
    }
    .menu-close-button::after {
      transform: rotate(-45deg);
    }
  }


  
